import React from "react";
import appFirebase from "../../firebase";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useRef, useState, useContext } from "react";
import { AuthContextOwner } from "../../context/AuthOwner";
import styled from "styled-components";
import Etiqueta from "../Etiqueta";
import Entrada from "../Entrada";
import AreaTexto from "../AreaTexto";
import BotonSubmit from "../BotonSubmit";
import Desplegable from "../Desplegable";
import { textoOscuro } from "../UI/variables";
import imagenTextoBottom from "../../assets/imagenes/imagenTextoBottom.png";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import MapaRegistroPropiedad from "../MapaRegistroPropiedad";

const db = getFirestore(appFirebase);
const storage = getStorage(appFirebase);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 24px 0;
`;
const StyledAreaTexto = styled(AreaTexto)`
  margin: 0 0 16px 0;
`;
const EntradaFile = styled(Entrada)`
  // display: none;
`;
const StyledLabel = styled.label`
  width: 200px;
  height: 200px;
  border: 2px dashed ${textoOscuro};
  margin: 8px 0 16px 60px;
`;
const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`;
const StyledP = styled.p`
  width: 328px;
`

const FormPropiedad = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContextOwner);
  const [departamento, setDepartamento] = useState("");
  const [distrito, setDistrito] = useState("");
  const [direccion, setDireccion] = useState("");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [imagen, setImagen] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [reglas, setReglas] = useState("");

  const [sourceImagen, setSourceImagen] = useState(imagenTextoBottom);
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);

  const fileInputRef = useRef(null);

  const manejarDepartamento = (valor) => {
    setDepartamento(valor);
  };
  const manejarDistrito = (e) => {
    //detectar y almacenar valor de input en const distrito
    setDistrito(e.target.value);
  };
  const manejarDireccion = (e) => {
    //detectar y almacenar valor de input en const direccion
    setDireccion(e.target.value);
  };

  const manejarDescripcion = (e) => {
    //detectar y almacenar valor de input en const descripcion
    setDescripcion(e.target.value);
  };

  const manejarReglas = (e) => {
    //detectar y almacenar valor de input en const descripcion
    setReglas(e.target.value);
  };

  const getLatitud = (latitud) => {
    setLatitud(latitud);
  };
  const getLongitud = (longitud) => {
    setLongitud(longitud);
  };
  // const manejarLatitud = (e) => {
  //   setLatitud(e.target.value);
  // };
  // const manejarLongitud = (e) => {
  //   setLongitud(e.target.value);
  // };
  const manejarImagen = (e) => {
    //detectar y almacenar archivo en const imagen
    setImagen(e.target.files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setSourceImagen(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setSourceImagen(imagenTextoBottom);
    }
  };
  const confirmarInicioDeEnvio = () => {
    setProcesoEnvioIniciado(true);
  };

  let urlImagen;
  const uuidPropiedad = uuidv4();

  const manejarEnvio = async (e) => {
    e.preventDefault();
    confirmarInicioDeEnvio();

    if (imagen) {
      // Si se ha seleccionado una imagen, carga la imagen al storage y obtén la URL
      const refImagen = ref(
        storage,
        `usuarios/propietarios/${currentUser[0].uid}/propiedades/${uuidPropiedad}/imagenesPropiedad/${currentUser[0].uuid}-${uuidPropiedad}-imagen1`
      ); //definimos ubicacion de imagen en storage firebase donde crearemos carpeta propiedades
      await uploadBytes(refImagen, imagen); //subimos la const imagen a la ubicacion definida en refImagen mediante la funcion uploadBytes
      //obtenemos la url de la imagen subida al storage
      urlImagen = await getDownloadURL(refImagen);
    }

    const newPropiedad = {
      departamento: departamento,
      distrito: distrito,
      direccion: direccion,
      descripcion: descripcion,
      reglas: reglas,
      imagen: urlImagen,
      latitud: latitud,
      longitud: longitud,
      idPropietario: currentUser[0].uid,
      celOwner: currentUser[0].celular,
      uuidPropiedad: uuidPropiedad,
      suscripcionActiva: true,
      disponibilidadPropiedad: false,
      preciosHabitaciones: []
    };

    //enviar datos recolectados al database
    try {
      await addDoc(collection(db, "propiedades"), { ...newPropiedad });
    } catch (error) {
      console.log(error);
    }
    // resetamos inputs tipo texto
    setDistrito("");
    setDireccion("");
    setDescripcion("");
    setReglas("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    //nos direccionamos a la pagina donde estan registradas las propiedades
    navigate("/AgregarPropiedades");
  };

  return (
    <section>
      <StyledForm onSubmit={manejarEnvio}>
        <Etiqueta>Departamento:</Etiqueta>
        <Desplegable onSeleccionChange={manejarDepartamento} />
        <Etiqueta>Distrito:</Etiqueta>
        <Entrada type="text" value={distrito} onChange={manejarDistrito} required/>
        <Etiqueta>Dirección completa y número:</Etiqueta>
        <Entrada type="text" value={direccion} onChange={manejarDireccion} placeholder="Ejm:  Av. los alamos N° 220" required/>
        <Etiqueta>Describa lo más resaltante de su propiedad:</Etiqueta>
        <StyledAreaTexto
          value={descripcion}
          onChange={manejarDescripcion}
          required
          maxLength={150}
        />

        <Etiqueta>Describa las reglas de su propiedad:</Etiqueta>
        <StyledAreaTexto
          value={reglas}
          onChange={manejarReglas}
          placeholder="Ejm: - No se permite tomar bebidas alcoholicas en las habitaciones, - etc."
        />

        <Etiqueta>Selecciona o toma 01 foto de tu propiedad:</Etiqueta>
        <EntradaFile
          type="file"
          name="image"
          accept="image/*"
          //capture="environment"
          onChange={manejarImagen}
          ref={fileInputRef}
          id="inputImagen"
          required
        />
        <StyledLabel htmlFor="inputImagen">
          <StyledImg src={sourceImagen} alt="Seleccionar fotografia de la propiedad"/>
        </StyledLabel>
        <Etiqueta>Ubica tu propiedad:</Etiqueta>
        <StyledP>Marca la ubicación de tu propiedad tocando sobre el mapa.</StyledP>
        <MapaRegistroPropiedad
          ubicacion={departamento}
          getLatitud={getLatitud}
          getLongitud={getLongitud}
          latitud={latitud}
          longitud={longitud}
        />
        {/* <Etiqueta>Latitud:</Etiqueta>
        <Entrada type="number" value={latitud} onChange={manejarLatitud} required/>
        <Etiqueta>Longitud:</Etiqueta>
        <Entrada type="number" value={longitud} onChange={manejarLongitud} required/> */}
        <BotonSubmit $large $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>
          {procesoEnvioIniciado ? "Registrando... ⌛⌛" : "Registrar Propiedad"}
        </BotonSubmit>
      </StyledForm>
    </section>
  );
};

export default FormPropiedad;
