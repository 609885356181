import React, { createContext, useEffect, useState } from "react";
import appFirebase from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, onSnapshot, collection, query, where} from "firebase/firestore";
import { useAuth } from "./AuthContext";

const auth = getAuth(appFirebase);
const db = getFirestore(appFirebase);

export const AuthContextAdmin = createContext();
export const AuthProviderAdmin = ({ children }) => {
  
  const { userData, usuarioActual } = useAuth(); //traemos datos de database y autentication de firebase desde el AuthContext */
  const [adminData, setAdminData] = useState({});
  const [propietarios, setPropietarios] = useState([])
  const [currentUser, setCurrentUser] = useState(null);
  const [cantidadUsuariosTotales, setCantidadUsuariosTotales] = useState([]);
  const [cantidadUsuariosDepartamento, setCantidadUsuariosDepartamento] = useState([]);
  
  //************************Conformacion de adminData con datos de database y autentication****************** */
  useEffect(() => {
    setAdminData({
      uid: usuarioActual.uid,
      correo: usuarioActual.email,
      rol: userData.rol,
      uuid: userData.uuid,
      nombre: userData.nombre
    })
  }, [userData]);
  //establecemos una conexion para que propietarios se actualizen en tiempo real cada vez que un dato en firebase cambia.
  //**************************************************PROPIETARIOS*********************************************** */
  useEffect(() => {
    onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase && adminData) {
        const q = query(collection(db, "usuarios"), where("rol", "==", "propietario"))
        const unsubscribeUsuarios = onSnapshot(q, (snapshot) => {
          const usuariosFirebase = snapshot.docs.map(doc => ({
            ...doc.data(),
            idUsuario: doc.id
          }));
          setPropietarios(usuariosFirebase);
        });
        return () => unsubscribeUsuarios();
      }
    })
  }, [adminData]);
  //****************************Definimos currentUser para compartir a componentes***************************** */
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase) {
        setCurrentUser([adminData, propietarios]);
      } else {
        setCurrentUser(null);
      }
    });
    return () => unsubscribeAuth();
  }, [adminData, propietarios]);

  return (
    <AuthContextAdmin.Provider value={{ currentUser, setCantidadUsuariosTotales, cantidadUsuariosTotales, cantidadUsuariosDepartamento, setCantidadUsuariosDepartamento }}>
      {children}
    </AuthContextAdmin.Provider>
  );
};