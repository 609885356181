import React, { useContext } from "react";
import styled from "styled-components";
import Titulo2 from "../../../components/Titulo2";
import Boton from "../../../components/Boton";
import { fondo } from "../../../components/UI/variables";
import { AuthContextAdmin } from "../../../context/AuthAdmin";

const StyledSection = styled.section`
    background-color: ${fondo};
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    align-items: center;
`
const TituloStyled= styled(Titulo2)`
    width: 100%;
    text-align: center;
`
const DivPropietario =styled.div`
    margin-top: 16px;
    width: 100%;
`
const DivEstudiante =styled.div`
    margin-top: 48px;
    width: 100%;
`
const DivTotal =styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: black;
    p{
        color: white;
        font-weight: bold;
        font-size: 18px;
    }
`
const DivCiudades =styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
`
const CardPropietarios =styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 88px;
    background-color: #7cb342;
    padding: 8px;
    margin: 16px 4px 0 4px;
    p{
        color: white;
        font-weight: bold;
        margin: 0;
    }
`
const CardEstudiantes =styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 88px;
    background-color: #26c6da ;
    padding: 8px;
    margin: 16px 4px 0 4px;
    p{
        color: white;
        font-weight: bold;
        margin: 0;
    }
`
const CantidadUsuarios = () => {
    const { cantidadUsuariosTotales, cantidadUsuariosDepartamento } = useContext(AuthContextAdmin);

    return (
        <StyledSection>
            <StyledHeader>
                <Boton to={`/HomeAdmin`}>Atras</Boton>
                <TituloStyled $naranja>Cantidad de usuarios</TituloStyled>
            </StyledHeader>

            <DivPropietario>
                <h1>PROPIETARIOS</h1>
                <DivTotal>
                    <p>Propietarios</p>
                    <p>{cantidadUsuariosTotales?.[0]?.totalPropietarios ?? 0}</p>
                </DivTotal>
                <DivCiudades>
                    {cantidadUsuariosDepartamento.map((ciudad, i) => (
                        <CardPropietarios key={i}>
                            <p>{ciudad.cantidadPropietarios ?? 0}</p>
                            <p>{ciudad.idCiudad}</p>
                        </CardPropietarios>
                    ))}
                </DivCiudades>
            </DivPropietario>
            
            <DivEstudiante>
                <h1>ESTUDIANTES</h1>
                <DivTotal>
                    <p>Estudiantes</p>
                    <p>{cantidadUsuariosTotales?.[0]?.totalEstudiantes ?? 0}</p>
                </DivTotal>
                <DivCiudades>
                    {cantidadUsuariosDepartamento.map((ciudad, i) => (
                        <CardEstudiantes key={i}>
                            <p>{ciudad.cantidadEstudiantes ?? 0}</p>
                            <p>{ciudad.idCiudad}</p>
                        </CardEstudiantes>
                    ))}
                </DivCiudades>
            </DivEstudiante>
        </StyledSection>
    );
};
export default CantidadUsuarios;