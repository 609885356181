import styled from "styled-components";
import BotonSubmit from "../../components/BotonSubmit"
import { colorPrimario} from "../UI/variables";
import { useState } from "react";
import appFirebase from "../../firebase";
import { getFirestore, updateDoc, doc } from "firebase/firestore";

const StyledSection=styled.section`
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 312px;
    height: 100%;
    display: flex;
    justify-content: space-between ;
`
const DivEncabezadoCard = styled.div`
    display: flex;
    justify-content: space-between;
    p{
        display: flex;
        width: 100%;
        color: ${colorPrimario};
        margin: 0;
        padding-bottom: 16px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 20px;
    }
`
const DivContenedor=styled.div`
    display: flex;
`
const DivTarjeta=styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
const DivInformacion=styled.div`
    display: flex;
    width: 100%;
    img{
        width: 104px;
        height: 96px;
    }
    p{
        width: 240px;
    }
`
const StyledText=styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: space-around;
    margin: 0px 0px 0px 8px;
    width: 200px;
    height: 100%;
    p{
        margin: 0;
        width: 200px;
    }
`
const DivMesesPagados=styled.div`
    display: flex;
    margin-top: 16px;
`
const DivCuentas=styled.div`
    width: 33.3%;
    border-right: solid 1px black;
    h4{
        margin: 0 0 16px 0;
        text-align: center;
        height: 40px;
        font-weight: 500;
        color: ${colorPrimario};
    }
    p{
        margin: 16px 0 16px 0;
        text-align: center;
        height: 40px;
        font-size: 18px;
        font-weight: bold;
    }
`
const PDeuda=styled.p`
    color: red;
`
const BotonRegistroPago=styled(BotonSubmit)`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8px 0px 0px 0px;
    height: 48px;
    font-size: 16px;
    p{
        color: white;
        margin: 0;
        font-weight: 500;
        font-size: 14px;
    }
`
const HabitacionAdministradaPagos=(props)=>{
    const db = getFirestore(appFirebase);
    const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);
    const mesesDeuda = props.periodoActual-props.periodoPagado
    
    //*****************************FUNCION PARA SUMAR DIAS***************************** */
    const sumarDias = (fecha, dias) => {
        const fechaSumada = new Date(fecha);
        fechaSumada.setDate(fechaSumada.getDate() + dias);
        return fechaSumada;
    }
    //**************************FUNCION PARA REGISTRAR PAGO*************************** */
    const RegistrarPago = async()=>{
        if(window.confirm(`Estas seguro de registrar el pago del mes ${(props.periodoPagado+1)}?`)){
            setProcesoEnvioIniciado(true);
            const datosRegistro = {
                periodoPagado: props.periodoPagado+1,
                fechaInicioPeriodoPorPagar: sumarDias(props.fechaFinPeriodoPorPagar.toDate(), 1),
                fechaFinPeriodoPorPagar: sumarDias((props.fechaFinPeriodoPorPagar.toDate()), 30),
            }
            try {
                const documentoRef = doc(db, "habitaciones", props.idHabitacion);
                await updateDoc(documentoRef, datosRegistro);
            } catch (error) {
                console.error("Error al realizar pago:", error);
            }
            alert("Pago Registrado por: S/. " + props.precio)
            setProcesoEnvioIniciado(false);
        }
    }

    return(
        <StyledSection>
            <DivEncabezadoCard>
                <p>{`Habitación N°${props.nroHabitacion}`}</p>
            </DivEncabezadoCard>
            <DivContenedor>
                <DivTarjeta>
                    <DivInformacion>
                        <img src={props.imagen} alt= "Fotografia de la habitacion seleccionada"/>
                        <StyledText>
                            <p><strong>Nombre:</strong>{` ${props.nombreCompleto}`}</p>
                            <p><strong>Celular:</strong>{` ${props.celular}`}</p>
                            <p><strong>Fecha de entrada:</strong>{` ${(props.fechaEntrada).toDate().toLocaleDateString()}`}</p>
                        </StyledText>
                    </DivInformacion>
                    <DivMesesPagados>
                        <DivCuentas>
                            <h4>Precio Habitación: </h4>
                            <p> S/.{props.precio}</p>
                        </DivCuentas>
                        <DivCuentas>
                            <h4>Meses de deuda:</h4>
                            <p>{mesesDeuda} meses</p>
                        </DivCuentas>
                        <DivCuentas>
                            <h4>Deuda Total:</h4>
                            <PDeuda>S/.{mesesDeuda * props.precio}</PDeuda>
                        </DivCuentas>
                    </DivMesesPagados>
                    <BotonRegistroPago onClick={RegistrarPago} $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>
                        Registrar Pago Mes {props.periodoPagado+1}
                        <p>{`${props.fechaInicioPeriodoPorPagar&&props.fechaFinPeriodoPorPagar ? `(${(props.fechaInicioPeriodoPorPagar.toDate()).toLocaleDateString()} - ${(props.fechaFinPeriodoPorPagar.toDate()).toLocaleDateString()})` :<></>}`}</p>
                    </BotonRegistroPago>
                </DivTarjeta>
            </DivContenedor>
        </StyledSection>
    )
}
export default HabitacionAdministradaPagos