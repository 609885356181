import styled from "styled-components"
import logoWhatsapp from "../../assets/iconos/logoWhatsapp.svg"
import logoFacebook from "../../assets/iconos/logoFacebook.svg"
import logoCorreo from "../../assets/iconos/logoCorreo.svg"
import { colorPrimario, colorSecundario } from "../UI/variables"
import EnviarWhatsapp from "../EnviarWhatsapp"

const StyledSection = styled.section`
    display: flex;
    justify-content: center;
    width: 330px;
`
const ContenedorInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 330px;
`
const StyledDivInstruccion = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
`
const StyledPNumero = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 20px;
    background-color: ${colorPrimario};
    width: 36px;
    height: 36px;
    color: white;
    font-size: 20px;
`
const StyledPInstruccion = styled.p`
    font-weight: bold;
    margin-left: 16px;
`
const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
`
const StyledOpcionesContacto = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colorSecundario};
    width: 264px;
    height: 40px;
    margin: 2px 0px;
`
const StyledP = styled.p`
    width: auto;
    height: 16px;
    margin: 0 0 0 8px;
    text-align: center;
`
const BoxContacto = () => {
  return (
    <StyledSection>
        <ContenedorInfo>
            <StyledDivInstruccion>
                <StyledPNumero>1.</StyledPNumero>
                <StyledPInstruccion>Contáctanos y adquiere tu plan:</StyledPInstruccion>
            </StyledDivInstruccion>
            <StyledDiv>
                <StyledOpcionesContacto>
                    <img src={logoWhatsapp} alt="Logo de Whatsapp"/>
                    <StyledP>916660482</StyledP>
                    <EnviarWhatsapp numero="51916660482" mensaje="Hola, me gustaría más información." />
                </StyledOpcionesContacto>
                {/* <StyledOpcionesContacto>
                    <img src={logoFacebook} alt="Logo de Facebook"/>
                    <StyledP>arkilados_conf</StyledP>
                </StyledOpcionesContacto> */}
                <StyledOpcionesContacto>
                    <img src={logoCorreo} alt="Icono de correo"/>
                    <StyledP>arkiladosatencion@gmail.com</StyledP>
                </StyledOpcionesContacto>
            </StyledDiv>
        </ContenedorInfo>
    </StyledSection>
  )
}

export default BoxContacto