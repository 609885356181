import React, { useContext, useState } from "react";
import styled from 'styled-components'
import HeaderAuthenticatedStudent from "../../../components/HeaderAuthenticatedStudent"
import Titulo1 from "../../../components/Titulo1"
import imgHabitacion from "../../../assets/imagenes/gatoEscogiendo.jpg"
import Desplegable from "../../../components/Desplegable"
import Etiqueta from "../../../components/Etiqueta"
import Boton from '../../../components/Boton'
import { fondo } from '../../../components/UI/variables'

import PiePaginaContacto from "../../../components/PiePaginaContacto"
import { AuthContextStudent } from "../../../context/AuthStudent";
import { getFirestore, collection, onSnapshot, query, where } from "firebase/firestore";
import appFirebase from "../../../firebase";

const StyledDiv=styled.div`
  background-color: ${fondo};
  display:flex;
  flex-direction:column;
  align-items: center;
  padding-top:88px;
  min-height: 80vh;
`
const StyledImg=styled.img`
  width: 360px;
  height: 336px;
`
const TextoBanner=styled(Titulo1)`
  position:absolute;
  transform: translate(0%,70%);
`
const StyledEtiqueta = styled(Etiqueta)`
  margin: 16px 0 8px 0;
`
const FooterRedesSociales=styled(PiePaginaContacto)`
    margin-top:auto;
`

const HomeStudentAuthenticated = () => {
  const db = getFirestore(appFirebase);
  const {setPropiedades} = useContext(AuthContextStudent)

  const [ubicacion, setUbicacion] = useState("");
  const manejarUbicacion = (valor) => {
    setUbicacion(valor);
  };

    //*********************************OBTENCION DE PROPIEDADES SEGUN SU UBICACION****************************** */
    const obtenerPropiedadesUbicacion = ()=>{
      const q = query(collection(db, "propiedades"),where("departamento", "==", `${ubicacion}`), where("disponibilidadPropiedad", "==", true))

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const propiedadesFirebase = snapshot.docs.map((doc) => ({
          ...doc.data(),
          idPropiedad: doc.id,
        }));
        setPropiedades(propiedadesFirebase);
      });
      return () => unsubscribe();
    }
  return (
    <>
        <HeaderAuthenticatedStudent/>
        <StyledDiv>
          <StyledImg src={imgHabitacion} alt="Imagen de bienvenida de un gato esperando que se abra una puerta"/>
          <TextoBanner>Bienvenido, existen muchas habitaciones disponibles esperandote!!</TextoBanner>
          <StyledEtiqueta>Busca en tu ciudad:</StyledEtiqueta>
          <Desplegable onSeleccionChange={manejarUbicacion}/>
          <Boton onClick={obtenerPropiedadesUbicacion} $large to={`/Propiedades/${ubicacion}`}>Explorar</Boton>
        </StyledDiv>
        {/* <FooterRedesSociales/> */}
    </>
  )
}
export default HomeStudentAuthenticated