import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BotonEditar from "../BotonEditar";
import { colorSecundario } from "../UI/variables";
import editar from "../../assets/iconos/editar.svg";
import eliminar from "../../assets/iconos/eliminar.svg";
import BotonEliminar from "../BotonEliminar";
import { deleteDoc, doc, getFirestore} from 'firebase/firestore'
import appFirebase from '../../firebase'
import { getStorage,ref,deleteObject } from 'firebase/storage'
import { useContext } from 'react'
import { AuthContextOwner } from '../../context/AuthOwner'
import { useNavigate } from "react-router-dom";

const StyledSection = styled.section`
  width: 328px;
  height: 224px;
  background-color: ${colorSecundario};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  margin: 8px 0 16px 0;
`;
const StyledDiv = styled.div`
  width: 312px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledImg = styled.img`
  width: 160px;
  height: 160px;
  object-fit: cover;
`;

const StyledP = styled.p`
  width: 144px;
  height: 160px;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const ContenedorBotones = styled.div`
  margin-top: 8px;
  width: 312px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BotonEliminarStyled = styled(BotonEliminar)`
  width: 96px;
`
const BotonEditarStyled = styled(BotonEditar)`
  width: 208px;
`
const Icon = styled.img`
  background-color: transparent;
  fill: white;
`;

const PropiedadRegistrada = (props) => {
  
  const db = getFirestore(appFirebase);
  const storage = getStorage(appFirebase);
  const {currentUser}=useContext(AuthContextOwner)
  const navigate=useNavigate()

  const idPropiedad=props.idPropiedad
  const uuidPropiedad = props.uuidPropiedad

  const habitaciones=currentUser[2]
  const [habitacionesPropiedad, setHabitacionesPropiedad]=useState([])

  //Obtenemos las habitaciones pertenecientes a una propiedad
  const getHabitacionesPropiedad=()=>{
    const docsHabitacionesPropiedad=[]
    habitaciones.map((habitacion)=>{
      if(habitacion.idPropiedad==idPropiedad){
        docsHabitacionesPropiedad.push(habitacion)
      }
      setHabitacionesPropiedad(docsHabitacionesPropiedad)
    })
  }
  useEffect(()=>{
    getHabitacionesPropiedad()
  },[])

  //Definimos funcionamiento para eliminar una propiedad y su imagen
  const deletePropiedad=async(id)=>{
    try{
      await deleteDoc(doc(db, "propiedades", id ))
      const refImagen=ref(storage,`usuarios/propietarios/${currentUser[0].uid}/propiedades/${uuidPropiedad}/imagenesPropiedad/${currentUser[0].uuid}-${uuidPropiedad}-imagen1`)
      await deleteObject(refImagen)
    }catch(error){
      console.log(error)
    }
  }

  //Definimos funcionamiento para eliminar las habitaciones pertenecientes a una propiedad y sus respectivas imagenes
  const deleteHabitaciones=()=>{
    habitacionesPropiedad.map(async(habitacion)=>{
      try{
        await deleteDoc(doc(db, "habitaciones", habitacion.idHabitacion ))
        const refImagen=ref(storage,`usuarios/propietarios/${currentUser[0].uid}/propiedades/${uuidPropiedad}/habitaciones/${habitacion.uuidHabitacion}/imagenesHabitacion/${uuidPropiedad}-${habitacion.uuidHabitacion}-imagen1`)
        await deleteObject(refImagen)
      }catch(error){
        console.log(error)
      }
    })
  }
  
  //Ejecutamos funcionamiento para eliminar una propiedad su imagen, y tambien eliminar sus habitaciones e imagenes
  const deleteTotal=()=>{
    if(window.confirm("ALERTA! ¿Estas seguro de eliminar esta propiedad? Si aceptas tambien se eliminaran todas sus habitaciones.")){
      deletePropiedad(idPropiedad)
      deleteHabitaciones()
    }
  }
  //Funcion para llevarnos a la pagina EditarPropiedad
  const editarPropiedad=async()=>{
    navigate(`/EditarPropiedad/${props.idPropiedad}`)
  }
  return (
    <StyledSection>
      <StyledDiv>
        <StyledImg src={props.imagen} alt="Fotografia de propiedad registrada"/>
        <StyledP>{props.direccion}</StyledP>
      </StyledDiv>
      <ContenedorBotones>
        <BotonEliminarStyled onClick={deleteTotal}>
          <Icon src={eliminar} alt="Eliminar"/>
          Eliminar
        </BotonEliminarStyled>
        <BotonEditarStyled onClick={editarPropiedad}>
          <Icon src={editar} alt="Editar"/>
          Editar
        </BotonEditarStyled>
      </ContenedorBotones>
    </StyledSection>
  );
};

export default PropiedadRegistrada;
