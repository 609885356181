import React, { useContext, useRef } from 'react'
import HeaderAuthenticatedAdmin from "../../../components/HeaderAuthenticatedAdmin"
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import dolarCalendario from '../../../assets/iconos/dolarCalendario.svg'
import usuarioBloqueado from '../../../assets/iconos/usuarioBloqueado.png'
import tiempoCaducado from '../../../assets/iconos/tiempoCaducado.png'
import estadisticas from '../../../assets/iconos/estadisticas.svg'
import { collection, getFirestore, onSnapshot, query } from 'firebase/firestore'
import appFirebase from '../../../firebase'
import { AuthContextAdmin } from '../../../context/AuthAdmin'

const DivContenedor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`
const StyledLink = styled(Link)`
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 80px;
  width: 160px;
  background-color: ${(props)=>props.color};
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 32px;
  img{
    width: 48px;
    height: 48px;
  }
  P{
    color: white;
  }
`
const HomeAdmin = () => {
  const db = getFirestore(appFirebase);
  const {setCantidadUsuariosTotales, setCantidadUsuariosDepartamento} = useContext(AuthContextAdmin)

  // Guardamos las referencias de las suscripciones para cancelarlas antes de abrir una nueva
  const unsubscribeTotalesRef = useRef(null);
  const unsubscribeDepartamentosRef = useRef(null);
 
  const obtenerEstadisticas = () => {
    // Si hay una suscripción activa, la cancelamos antes de crear una nueva
    if (unsubscribeTotalesRef.current) unsubscribeTotalesRef.current();
    if (unsubscribeDepartamentosRef.current) unsubscribeDepartamentosRef.current();
 
    // Consulta para la cantidad total de usuarios
    const qTotales = query(collection(db, "estadisticas"));
    unsubscribeTotalesRef.current = onSnapshot(qTotales, (snapshot) => {
      const totalesUsuarios = snapshot.docs.map((doc) => ({
        ...doc.data(),
        idEstadisticas: doc.id,
      }));
      setCantidadUsuariosTotales(totalesUsuarios);
    });
 
    // Consulta para usuarios por departamento
    const qDepartamentos = query(collection(db, "estadisticas", "resumen", "ciudades"));
    unsubscribeDepartamentosRef.current = onSnapshot(qDepartamentos, (snapshot) => {
      const usuariosDepartamentos = snapshot.docs.map((doc) => ({
        ...doc.data(),
        idCiudad: doc.id,
      }));
      setCantidadUsuariosDepartamento(usuariosDepartamentos);
    });
  };
  
  return (
    <section>
      <HeaderAuthenticatedAdmin/>
      <DivContenedor>
        <h1>Gestionar Propietarios</h1>
        <StyledLink to={"/GestionarSuscripciones"} color="#4ABDD2;">
          <img src={dolarCalendario} alt="Gestionar Suscripciones"/>
          <p>Gestionar Suscripciones</p>
        </StyledLink>

        <StyledLink to={"/BloquearPropietarios"} color="#e74c3c ">
          <img src={usuarioBloqueado} alt="Bloquear Propietarios"/>
          <p>Bloquear Propietarios</p>
        </StyledLink>

        <h1>Notificaciones Habitaciones</h1>
        <StyledLink to={"/VerHabitacionesVencidas"} color="#f39c12">
          <img src={tiempoCaducado} alt="HabitacionesVencidas"/>
          <p>Ver Habitaciones Vencidas</p>
        </StyledLink>

        <h1>Estadisticas Plataforma</h1>
        <StyledLink onClick={obtenerEstadisticas} to={"/CantidadUsuarios"} color="#7cb342">
          <img src={estadisticas} alt="HabitacionesVencidas"/>
          <p>Cantidad de usuarios</p>
        </StyledLink>
      </DivContenedor>
    </section>
  )
}

export default HomeAdmin