import React, { useEffect, useState, useContext } from "react";
import { AuthContextOwner } from "../../context/AuthOwner";
import styled from "styled-components";
import {
  colorPrimario,
  colorSecundario,
  textoClaro,
  textoOscuro,
} from "../UI/variables";
import HabitacionAdministradaRegistro from "../HabitacionAdministradaRegistro";
import flechaAbajo from "../../assets/iconos/flechaAbajo.svg";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DivSeleccionHabitacion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 248px;
  width: 356px;
  margin-bottom: 16px;
  border: dashed 2px gray;
  p {
    text-align: center;
    width: 300px;
    font-size: 16px;
    font-weight: 500;
    color: gray;
    margin: 0 0 4px 0;
  }
  img {
    width: 36px;
    height: 36px;
  }
`;
const StyledDiv = styled.div`
  display: inline-flex;
  width: 352px;
  flex-wrap: wrap;
`;
const HabitacionOcupada = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 68px;
  height: 48px;
  font-size: 14px;
  color: ${textoClaro};
  background-color: ${colorPrimario};
  border: solid 1px white;
  font-weight: bold;
  transition: font-size ease 0.5s;
  &:hover {
    border: solid 4px black;
    font-size: 16px;
  }
  &:active {
    border: solid 4px black;
    font-size: 16px;
  }
`;
const HabitacionDisponible = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 68px;
  height: 48px;
  font-size: 14px;
  color: ${textoOscuro};
  background-color: ${colorSecundario};
  border: solid 1px white;
  font-weight: bold;
  transition: font-size ease 0.5s;
  &:hover {
    border: solid 4px black;
    font-size: 16px;
  }
  &:active {
    border: solid 4px black;
    font-size: 16px;
  }
`;
const DivLeyenda = styled.div`
  margin: 16px;
  display: flex;
  width: 300px;
  justify-content: space-around;
`;
const DivElementoLeyenda = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    width: 120px;
    margin-top: 4px;
  }
`;
const StyledP = styled.p`
  color: ${colorPrimario};
  text-align: center;
  font-weight: 500;
`;

const ListaHabitacionesAdministradasRegistro = ({ contarHabitaciones, propiedadSeleccionada }) => {
  const idPropiedad = propiedadSeleccionada.idPropiedad;
  const { currentUser } = useContext(AuthContextOwner);
  const habitacionesOwner = currentUser[2];
  const [lista, setLista] = useState([]);
  const [habitacionSeleccionada, setHabitacionSeleccionada] = useState(null);

  const habitacionesPropiedad = [];
  const habitacionesDisponibles = [];
  const habitacionesOcupadas = [];
  habitacionesOwner.map((habitacionOwner) => {
    if (habitacionOwner.idPropiedad == idPropiedad) {
      habitacionesPropiedad.push(habitacionOwner);
    }
    if (habitacionOwner.idPropiedad == idPropiedad && habitacionOwner.disponible == true ) {
      habitacionesDisponibles.push(habitacionOwner);
    }
    if (habitacionOwner.idPropiedad == idPropiedad && habitacionOwner.disponible == false ) {
      habitacionesOcupadas.push(habitacionOwner);
    }
  });

  useEffect(() => {
    setLista(habitacionesPropiedad);
  }, [propiedadSeleccionada, habitacionesOwner, currentUser]);

  //ordenamos las habitaciones dentro del array lista
  lista.sort(function (a, b) {
    return a.NroHabitacion - b.NroHabitacion;
  });
  //contamos las habitaciones dentro del array lista
  useEffect(() => {
    contarHabitaciones(lista.length);
  }, [lista, contarHabitaciones]);

  //********************************************* */
  useEffect(() => {
    setHabitacionSeleccionada(null);
  }, [propiedadSeleccionada]);

  const seleccionarHabitacion = (i) => {
    setHabitacionSeleccionada(i);
  };

  return lista.length > 0 ? (
    <StyledSection>
      {habitacionSeleccionada !== null ? (
        <HabitacionAdministradaRegistro
          key={habitacionSeleccionada}
          imagen={lista[habitacionSeleccionada].Imagen}
          nroHabitacion={lista[habitacionSeleccionada].NroHabitacion}
          idHabitacion={lista[habitacionSeleccionada].idHabitacion}
          disponible={lista[habitacionSeleccionada].disponible}
          dni={lista[habitacionSeleccionada].dni}
          precio={lista[habitacionSeleccionada].Precio}
          nombreCompleto={lista[habitacionSeleccionada].nombreCompleto}
          celular={lista[habitacionSeleccionada].celular}
          garantia={lista[habitacionSeleccionada].garantia}
          fechaEntrada={lista[habitacionSeleccionada].fechaEntrada}
          periodoActual={lista[habitacionSeleccionada].periodoActual}
          periodoPagado={lista[habitacionSeleccionada].periodoPagado}
          fechaInicioPeriodo={lista[habitacionSeleccionada].fechaInicioPeriodo}
          fechaFinPeriodo={lista[habitacionSeleccionada].fechaFinPeriodo}
          idPropiedad={lista[habitacionSeleccionada].idPropiedad}
          habitacionesPropiedad={habitacionesPropiedad}
          habitacionesDisponibles={habitacionesDisponibles}
          //setHabitacionSeleccionada={setHabitacionSeleccionada}
          propiedadSeleccionada={propiedadSeleccionada}
        />
      ) : (
        <DivSeleccionHabitacion>
          <p>
            Para registrar un inquilino presiona en la habitación (ejm: Hab 1) donde
            quieres registrarlo
          </p>
          <img src={flechaAbajo} alt="Selecciona una habitacion para registrar un inquilino"/>
        </DivSeleccionHabitacion>
      )}
      <h1>Habitaciones</h1>
      <StyledDiv>
        {lista.map((list, i) => (
          <div key={i}>
            {list.disponible ? (
              <HabitacionDisponible onClick={() => seleccionarHabitacion(i)}>
                Hab {list.NroHabitacion}
              </HabitacionDisponible>
            ) : (
              <HabitacionOcupada onClick={() => seleccionarHabitacion(i)}>
                Hab {list.NroHabitacion}
              </HabitacionOcupada>
            )}
          </div>
        ))}
      </StyledDiv>
      <DivLeyenda>
        <DivElementoLeyenda>
          <HabitacionOcupada>{habitacionesOcupadas.length}</HabitacionOcupada>
          <p>Habitación(es) Ocupada(s)</p>
        </DivElementoLeyenda>
        <DivElementoLeyenda>
          <HabitacionDisponible> {habitacionesDisponibles.length} </HabitacionDisponible>
          <p>Habitación(es) Disponible(s)</p>
        </DivElementoLeyenda>
      </DivLeyenda>
    </StyledSection>
  ) : (
    <StyledP>
      Aún no tienes ninguna habitación registrada en esta propiedad, vuelve a
      inicio y registra tus habitaciones
    </StyledP>
  );
};

export default ListaHabitacionesAdministradasRegistro;
