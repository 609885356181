import React from 'react'
import { useRef } from 'react';
import styled from 'styled-components'
import { colorPrimario, colorSecundario, fondo } from '../../../components/UI/variables'

import HeaderOwner from "../../../components/HeaderOwner"
import Titulo1 from "../../../components/Titulo1"
import BeneficiosOwner from "../../../components/BeneficiosOwner"

import Titulo2 from "../../../components/Titulo2"
import PiePaginaContacto from "../../../components/PiePaginaContacto"
import portadaOwners from "../../../assets/imagenes/portadaOwners.png"
import FormOwner from '../../../components/FormOwner'
import Boton from '../../../components/Boton'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${fondo};
  padding-top: 88px;
  `
const TextoBanner = styled(Titulo1)`
  color: blue;
  position:absolute;
  transform: translate(0%,250%);
`
const BotonCallToAction = styled.button`
  border: none;
  width: 200px;
  height: 36px;
  background-color: ${colorPrimario};
  font-size: 16px;
  color: white;
  font-weight: bold; 
  border-radius: 24px;
  position:absolute;
  transform: translate(0%,700%);
  transition: background-color 1s ease;
  &:hover {
    background-color: black;
    color: white;
  }
  &:active {
    background-color: black;
    color: white;
  }
`
const StyledImg = styled.img`
  height: 312px;
  width: 328px;
  object-fit: cover;
  opacity: 0.9;
`
const StyledTitulo2 = styled(Titulo2)`
  margin: 24px 0 0 0;
`
const StyledDivContactanos = styled.div`
  /* width: 328px; */
  width: 100%;
  background-color: ${colorSecundario};
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
  p{
    /* font-size: 16px; */
    font-weight: 500;
    color: black;
    margin: 8px 0px;
  }
  `
const StyledBoton=styled(Boton)`
  background: none;
  border: solid 1px #3AA6B9;
  color: #3AA6B9;
  margin: 0px 8px;
  `

const HomeOwner = () => {
  const formularioRef = useRef(null);

  const scrollToFormulario = () => {
    if (formularioRef.current) {
      formularioRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <HeaderOwner/>
      <StyledDiv>
        <StyledImg src={portadaOwners} alt='Propietarios administrando sus arkileres desde un celular'/>
        <TextoBanner>Con "Arkilados" administra tus habitaciones en alquiler fácil, rápido y desde tu celular!</TextoBanner>
        <BotonCallToAction onClick={scrollToFormulario} >
          {`Empieza ya!! >`}
        </BotonCallToAction>
        <BeneficiosOwner/>
        {/* <iframe width="328" height="144" src="https://www.youtube.com/embed/C5fLxtJH2Qs?list=RDC5fLxtJH2Qs" title="Egzod &amp; Maestro Chives - Royalty (ft. Neoni) | NCS - Copyright Free Music" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
        <StyledTitulo2>Inicia con tu registro</StyledTitulo2>
        <div ref={formularioRef}>
          <FormOwner />
        </div>
        <StyledDivContactanos>
          <p>¿Tienes alguna duda?</p>
          <StyledBoton to='/contacto'>
            Contáctanos
          </StyledBoton>
        </StyledDivContactanos>
      </StyledDiv>
      {/* <PiePaginaContacto/> */}
    </>
  )
}

export default HomeOwner