import React from 'react'
import styled from 'styled-components'
import HeaderGeneral from "../../../components/HeaderGeneral"
import Titulo1 from "../../../components/Titulo1"
import estudiantes from "../../../assets/imagenes/estudiantes.png"
import Boton from '../../../components/Boton'
import { colorPrimario, fondo } from '../../../components/UI/variables'
import BeneficiosStudent from "../../../components/BeneficiosStudent"
import PiePaginaContacto from "../../../components/PiePaginaContacto"

const StyledDiv=styled.div`
  background-color: ${fondo};
  height: calc(100vh - 88px);
  display:flex;
  flex-direction:column;
  align-items: center;
  padding-top:88px;
`
const StyledImg=styled.img`
  width: 360px;
  height: 352px;
  margin-top: 0px;
`
const TextoBanner=styled(Titulo1)`
  position:absolute;
  transform: translate(0%,50%);
  color: blue;
`
const BotonRegistrate=styled(Boton)`
  background-color: ${colorPrimario};
  text-align:center;
  position:absolute;
  transform: translate(0%,680%);
  color: white;
  border-radius: 24px;
  transition: background-color 1s ease;
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`
const BotonPropietario=styled(Boton)`
  width:328px;
  background-color:transparent;
  color: black;
  border: 3px solid ${colorPrimario};
  font-weight:500;
  margin: 29px 0px;
  border-radius: 24px;
  transition: background-color 1s ease;
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`
const HomeStudent = () => {
  return (
    <>
        <HeaderGeneral/>
        <StyledDiv>
          <StyledImg src={estudiantes} alt='Grupo de estudiantes contentos buscando habitaciones a traves de sus celulares'/>
          <TextoBanner>Si eres estudiante encuentra tu habitación perfecta y descubre tu propio camino!!! lml</TextoBanner>
          <BotonRegistrate $large to="/RegistroStudent">¡Regístrate AQUI, es Totalmente Gratis!</BotonRegistrate>
          <BeneficiosStudent/>
          <BotonPropietario to="/HomeOwner">Eres propietario? Arkila con nosotros AQUI</BotonPropietario>
        </StyledDiv>
        {/* <PiePaginaContacto/> */}
    </>
  )
}

export default HomeStudent