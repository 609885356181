import React from 'react'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'

import Titulo2 from "../../../components/Titulo2"
import FormHabitacion from '../../../components/FormHabitacion'
import Boton from '../../../components/Boton'

const StyledSection = styled.section`
  background-color: ${fondo};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledHeader = styled.header`
  height: 40px;
  width: 96vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`

const RegistroHabitacion = () => {
  return (
    <StyledSection>
      <StyledHeader>
        <Boton to={`/AgregarHabitaciones`}>Atras</Boton>
        <TituloStyled $naranja>Registro Habitación</TituloStyled>
      </StyledHeader>
      <FormHabitacion/>
    </StyledSection>
  )
}

export default RegistroHabitacion