import styled from "styled-components";
import { textoOscuro } from "./UI/variables";
import { useState, useEffect } from "react";

const StyledSelect = styled.select`
  width: 328px;
  height: 36px;
  border-radius: 5px;
  margin: 8px 0px 8px 0px;
  border-color: ${textoOscuro};
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`;
const Desplegable = ({ onSeleccionChange, departamento }) => {
  // const ciudades = ["Arequipa", "Cuzco", "Moquegua", "Puno"];
  const ciudades = ["Puno"];
  const [valorSeleccionado, setValorSeleccionado] = useState("");

  const manejoCambioSeleccion = (e) => {
    const valor = e.target.value;
    setValorSeleccionado(valor);
    onSeleccionChange(valor);
  };
  // este useEffect solo funciona cuando se recibe mediante props el valor de "departamento", y esto solo ocurre cuando editamos una propiedad.
  useEffect(() => {
    if (departamento) {
      setValorSeleccionado(departamento);
    }
  }, [departamento]);

  return (
    <StyledSelect
      value={valorSeleccionado}
      onChange={manejoCambioSeleccion}
      required
    >
      <option value="" disabled>
        Selecciona tu ciudad
      </option>
      {ciudades.map((ciudad, index) => (
        <option value={ciudad} key={index}>
          {ciudad}
        </option>
      ))}
    </StyledSelect>
  );
};
export default Desplegable;
