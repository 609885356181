import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fondo } from "../UI/variables";
import HabitacionVencida from "../HabitacionVencida";
import { AuthContextAdmin } from '../../context/AuthAdmin';
import BotonSubmit from "../../components/BotonSubmit"
import { Timestamp, collection, getFirestore, onSnapshot, query, where } from "firebase/firestore"
import appFirebase from "../../firebase"
import * as XLSX from 'xlsx';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${fondo};
  align-items: center;
  margin-top: 16px;
`;
const StyledDiv= styled.div`
  width: 328px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
const BotonHabitaciones= styled(BotonSubmit)`
  width: 146px;
  height: 64px;
  margin-top: 16px;
  background-color: white;
  color: black;
  border: 1px solid red;
  &:hover {
    background-color: white;
  }
  &:active {
    background-color: red;
    font-size: 16px;
    color: white;
  }
`
const BotonExportar= styled(BotonSubmit)`
  width: 120px;
  height: 64px;
  margin-top: 16px;
  background-color: white;
  color: green;
  border: 1px solid green;
  &:hover {
    background-color: white;
  }
  &:active {
    background-color: grey;
    font-size: 16px;
    color: white;
  }
`
const StyledP= styled.p`
  font-size: 16px;
  text-align: center;
`
const ListaHabitacionesVencidas = () => {
  const db = getFirestore(appFirebase);
  const { currentUser } = useContext(AuthContextAdmin);
  const propietarios = currentUser[1];
  const propietariosSuscripcionActiva = propietarios.filter(propietario => propietario.suscripcionActiva==true)

  const [lista, setLista] = useState([]);
  const [habitacionesVencidas, setHabitacionesVencidas] = useState([])
  const datosExportar = [];

  //**********************************************FUNCION PARA SUMAR DIAS**************************************************** */
  const sumarDias = (fecha, dias) => {
    const fechaSumada = new Date(fecha);
    fechaSumada.setDate(fechaSumada.getDate() + dias);
    return fechaSumada;
  }
  const hoy = sumarDias(new Date(),0)
  const fechaConsulta = sumarDias(hoy, 30)
  const fechaConsultaTimeStamp = Timestamp.fromDate(fechaConsulta)
  // *****************************FUNCION PARA LLAMAR HABITACIONES VENCIDAS DE TODOS LOS PROPIETARIOS******************************* */
  const llamarHabitacionesVencidas = ()=>{
    const q = query(collection(db, "habitaciones"), where("fechaFinPeriodoPorPagar", "<=", fechaConsultaTimeStamp))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const habitacionesFirebase = snapshot.docs.map((doc) => ({
        ...doc.data(),
        idHabitacion: doc.id,
      }));
      setHabitacionesVencidas(habitacionesFirebase);
    });
    return () => unsubscribe();
  }
  //**********************************************FUNCION PARA EXPORTAR DATOS A EXCEL******************************************* */
  const exportToExcel = (data, fileName) => {
    // Crear un nuevo libro de trabajo (workbook)
    const wb = XLSX.utils.book_new();
  
    // Convertir el array de JSON en una hoja de trabajo (worksheet)
    const ws = XLSX.utils.json_to_sheet(data);
  
    // Añadir la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, "Datos");
  
    // Exportar el archivo Excel
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };
  
  const manejarExportar = () => {
    exportToExcel(datosExportar, `Habitaciones Vencidas ${hoy.toLocaleDateString()}`);
  };
  
  useEffect(() => {
    const habitacionesVencidasSuscripcionActiva = habitacionesVencidas.filter(habitacionVencida => propietariosSuscripcionActiva.some(propietario => propietario.idUsuario === habitacionVencida.idPropietario))
    setLista(habitacionesVencidasSuscripcionActiva);
  }, [habitacionesVencidas]);
  // *******************************************************ORDENAMOS LA LISTA*************************************************** */
  lista.sort((a, b) => {
    if (a.idPropietario < b.idPropietario) {
      return -1;
    }
    if (a.idPropietario > b.idPropietario) {
      return 1;
    }
    return 0;
  });


  return (
    <StyledSection>
      <StyledDiv>
        <BotonHabitaciones onClick={()=>llamarHabitacionesVencidas()}>
          Ver Habitaciones Vencidas
        </BotonHabitaciones>
        <BotonExportar onClick={manejarExportar}>
          Exportar a excel
        </BotonExportar>
      </StyledDiv>

      {
        lista.length>0
        ?
          (lista.map((list, i) => {
            const propietario = propietarios.filter(propietario => propietario.idUsuario==list.idPropietario)
            
            datosExportar.push({
              dniPropietario: propietario[0].dni,
              apellidoPropietario: propietario[0].apellido,
              nombrePropietario: propietario[0].nombre,
              celularPropietario: propietario[0].celular,
              nroHabitacion: list.NroHabitacion,
              estudiante: list.nombreCompleto,
              celularEstudiante: list.celular,
            })
            
            return(
              <HabitacionVencida
                key={i}
                estudiante={list.nombreCompleto}
                celularEstudiante={list.celular}
                nroHabitacion={list.NroHabitacion}
                nombrePropietario={propietario[0].nombre}
                apellidoPropietario={propietario[0].apellido}
                dniPropietario={propietario[0].dni}
                celularPropietario={propietario[0].celular}
              />
            )
          }))
        :
        <StyledP>* Haz click en el boton para ver las habitaciones vencidas.</StyledP>
      }
    </StyledSection>
  );
};

export default ListaHabitacionesVencidas;
