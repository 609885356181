import styled from "styled-components";
import BotonSubmit from "../../components/BotonSubmit";
import { colorPrimario, colorSecundario, colorTerciario, textoClaro } from "../UI/variables";
import { useEffect, useState } from "react";
import appFirebase from "../../firebase";
import { getFirestore, updateDoc, doc } from "firebase/firestore";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
  background: ${colorSecundario};
  width: 360px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const DivEncabezadoCardOcupado = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    background-color: ${colorPrimario};
    display: flex;
    color: ${textoClaro};
    margin: 0;
    padding: 8px 8px;
    align-items: center;
    justify-content: center;
    border: solid 1px ${textoClaro};
    font-weight: 400;
    font-weight: bold;
  }
  p{
    margin: 8px 0 0 0;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;
const DivEncabezadoCardDisponible = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    background-color: ${colorTerciario};
    display: flex;
    width: 100%;
    color: ${textoClaro};
    margin: 0;
    padding: 8px 8px;
    align-items: center;
    justify-content: center;
    border: solid 1px ${textoClaro};
    font-weight: 400;
    font-weight: bold;
  }
`;
const DivTarjeta = styled.div`
  width: 360px;
  height: 216px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  img {
    width: 88px;
    height: 96px;
  }
`;
const StyledForm = styled.form`
  display: flex;
  align-items: center;
  input {
    margin-bottom: 8px;
    width: auto;
    height: 24px;
    box-sizing: content-box;
  }
`;
const DivInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 8px;

`
const StyledDiv = styled.div`
  display: flex;
  height: 168px;
  justify-content: space-between;
  align-items: center;
  img{
    width: 88px;
    height: 96px;
  }
`
const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  margin: 0px 8px 0px 0px;
  width: 160px;
  p {
    margin: 8px 0 0 0;
  }
  
`;
const BotonRegistrar = styled(BotonSubmit)`
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  margin: 0;
  border-radius: 8;
  background: #82cd47;
  &:hover {
    background-color: ${(props) => props.procesoEnvioIniciado==true ? "black" : "#82cd47"};
    font-size: 16px;
  }
  &:active {
    background-color: ${(props) => props.procesoEnvioIniciado==true ? "black" : "#82cd47"};
    font-size: 16px;
  }
`;
const BotonDesocupar = styled(BotonSubmit)`
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  margin: 0;
  border-radius: 8;
  background-color: gray;
  &:hover {
    background-color: ${(props) => props.procesoEnvioIniciado==true ? "black" : "gray"};
    font-size: 16px;
  }
  &:active {
    background-color: ${(props) => props.procesoEnvioIniciado==true ? "black" : "gray"};
    font-size: 16px;
  }
`;
const HabitacionAdministradaRegistro = (props) => {
  const db = getFirestore(appFirebase);

  const [dni, setDni] = useState("");
  const [nombre, setNombre] = useState("");
  const [celular, setCelular] = useState("");
  const [garantia, setGarantia] = useState("");
  const [fechaSeleccionada, setFechaSeleccionada] = useState("");
  
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);
  const propiedadSeleccionada = props.propiedadSeleccionada
  const preciosHabitacionesFirebase = propiedadSeleccionada.preciosHabitaciones
  const [preciosHabitacionesEnviar, setPreciosHabitacionesEnviar] = useState([])

  const [disponibilidadPropiedad, setDisponibilidadPropiedad] = useState("")
  const totalHabitacionesDisponibles=props.habitacionesDisponibles.length

  const hoy = new Date().toISOString().split("T")[0];

  const manejarDni = (e) => {
    if (e.target.value.length <= 8) {
      setDni(e.target.value);
    }
  };
  const manejarNombre = (e) => {
    setNombre(e.target.value);
  };
  const manejarCelular = (e) => {
    if (e.target.value.length <= 9) {
      setCelular(e.target.value);
    }
  };
  const manejarGarantia = (e) => {
    if (e.target.value.length <= 9) {
      setGarantia(e.target.value);
    }
  };
  const manejarFechaSeleccionada = (e) => {
    setFechaSeleccionada(e.target.value);
  };

  //***********Funcionalidad para retirar del array preciosHabitaciones el precio de la habitacion seleccionada que se ocupara**************** */
  useEffect(()=>{
    if(props.disponible==true){
      const precioEliminar = parseInt(props.precio)
      if(precioEliminar){
        const index = preciosHabitacionesFirebase.indexOf(precioEliminar)
        if(index!=-1){
          const preciosHabitaciones = [...preciosHabitacionesFirebase]
          preciosHabitaciones.splice(index,1)
          setPreciosHabitacionesEnviar(preciosHabitaciones)
        }
      }
    }else{
      const precioIncorporado = parseInt(props.precio)
      const preciosHabitaciones = [...preciosHabitacionesFirebase]
      preciosHabitaciones.push(precioIncorporado)
      setPreciosHabitacionesEnviar(preciosHabitaciones)
    }
  },[props.idHabitacion])
  //*****************************FUNCION PARA SUMAR DIAS****************************** */
  const sumarDias = (fecha, dias) => {
    const fechaSumada = new Date(fecha);
    fechaSumada.setDate(fechaSumada.getDate() + dias);
    return fechaSumada;
  };

  useEffect(()=>{
    if (props.disponible == true) {
      if((totalHabitacionesDisponibles-1)>=1){
        setDisponibilidadPropiedad(true)
      }else{
        setDisponibilidadPropiedad(false)
      }
    } else {
      if((totalHabitacionesDisponibles+1)>=1){
        setDisponibilidadPropiedad(true)
      }
    }
  },[totalHabitacionesDisponibles])

  //****************FUNCION PARA REGISTRAR ESTUDIANTES EN UNA HABITACION**************** */
  const registrarEstudiantes = async () => {
    setProcesoEnvioIniciado(true); 
    const fechaEntrada = new Date(fechaSeleccionada).setHours(0, 0, 0, 0);
    const fechaFinPeriodo = sumarDias(fechaEntrada, 30);

    const datosRegistro = {
      dni: dni,
      nombreCompleto: nombre,
      celular: celular,
      garantia: garantia,
      disponible: false,
      fechaEntrada: sumarDias(fechaEntrada, 1),
      fechaInicioPeriodoPorPagar: sumarDias(fechaEntrada, 1),
      fechaFinPeriodoPorPagar: fechaFinPeriodo,
      periodoPagado: 0,
    };
    const datosRegistroPropiedad = {
      disponibilidadPropiedad: disponibilidadPropiedad,
      preciosHabitaciones: preciosHabitacionesEnviar,
    };
    if(window.confirm("Estas seguro de registrar al estudiante?")){
      try {
        const documentoRefHabitaciones = doc(db, "habitaciones", props.idHabitacion);
        await updateDoc(documentoRefHabitaciones, datosRegistro);
        const documentoRefPropiedades = doc(db, "propiedades", props.idPropiedad);
        await updateDoc(documentoRefPropiedades, datosRegistroPropiedad);
        alert("Estudiante Registrado");
      } catch (error) {
        console.error("Error al registrar estudiante", error);
      }
      setDni("");
      setNombre("");
      setCelular("");
    }
    setProcesoEnvioIniciado(false);
    // props.setHabitacionSeleccionada(null);
  };
  //**************************FUNCION PARA DESOCUPAR UNA HABITACION********************* */
  const desocuparHabitacion = async () => {
    setProcesoEnvioIniciado(true);
    const datosRegistro = {
      dni: "",
      nombreCompleto: "",
      celular: "",
      garantia: "",
      disponible: true,
      fechaEntrada: "",
      fechaInicioPeriodoPorPagar: "",
      fechaFinPeriodoPorPagar: "",
      periodoPagado: 0,
    };
    const datosRegistroPropiedad = {
      disponibilidadPropiedad: disponibilidadPropiedad,
      preciosHabitaciones: preciosHabitacionesEnviar,
    };
    if(window.confirm("Estas seguro de desocupar la habitacion?")){
      try {
        const documentoRefHabitaciones = doc(db, "habitaciones", props.idHabitacion);
        await updateDoc(documentoRefHabitaciones, datosRegistro);
        const documentoRefPropiedades = doc(db, "propiedades", props.idPropiedad);
        await updateDoc(documentoRefPropiedades, datosRegistroPropiedad);
        alert("Habitacion Desocupada");
      } catch (error) {
        console.error("Error al desocupar habitacion:", error);
      }
    }
    setProcesoEnvioIniciado(false);
    // props.setHabitacionSeleccionada(null);
  };

  const manejarSubmit = (event)=>{
    event.preventDefault();
    if (props.disponible == true) {
      registrarEstudiantes();
    } else {
      desocuparHabitacion();
    }
  }
  return (
    <StyledSection>
      {props.disponible == false ? (
        <DivEncabezadoCardOcupado>
          <h3>{`Habitación N°${props.nroHabitacion}`}</h3>
        </DivEncabezadoCardOcupado>
      ) : (
        <DivEncabezadoCardDisponible>
          <h3>{`Habitación N°${props.nroHabitacion}`}</h3>
        </DivEncabezadoCardDisponible>
      )}
      <DivTarjeta>
        <StyledForm onSubmit={manejarSubmit}>
            <img src={props.imagen} alt="Fotografia de la habitacion seleccionada" />
            <DivInputs>
              {props.disponible == true ? (
                <input value={nombre} onChange={manejarNombre} placeholder="Nombre completo:" required />
              ) : (
                <></>
              )}
              <StyledDiv>
                <StyledText>
                  {props.disponible == false && (
                    <p><strong>Nombre:</strong> {` ${props.nombreCompleto}`}</p>
                  )}
                  {props.disponible == true ? (
                    <input type="number" value={dni} onChange={manejarDni} placeholder="DNI:" required />
                  ) : (
                    <p><strong>DNI:</strong> {` ${props.dni}`} </p>
                  )}
                  {props.disponible == true ? (
                    <input type="number" value={celular} onChange={manejarCelular} placeholder="Celular:" required />
                  ) : (
                    <p><strong>Celular:</strong> {` ${props.celular}`} </p>
                  )}
                  {props.disponible == true ? (
                    <input type="number" value={garantia} onChange={manejarGarantia} placeholder="Garantia(S/.):" required />
                  ) : (
                    <p><strong>Garantía(S/.):</strong> {` ${props.garantia}`} </p>
                  )}
                  {props.disponible == true ? (
                    <>
                      <label>Fecha de entrada:</label>
                      <input
                        type="date"
                        value={fechaSeleccionada}
                        max={hoy}
                        onChange={manejarFechaSeleccionada}
                        required
                      />
                    </>
                  ) : (
                    <p><strong>Fecha de entrada:</strong> {` ${props.fechaEntrada.toDate().toLocaleDateString()}`}</p>
                  )}
                </StyledText>
                {props.disponible == true ? (
                  <BotonRegistrar $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>Registrar</BotonRegistrar>
                  ) : (
                  <BotonDesocupar $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>Desocupar</BotonDesocupar>
                )}
              </StyledDiv>
            </DivInputs>
        </StyledForm>
      </DivTarjeta>
    </StyledSection>
  );
};
export default HabitacionAdministradaRegistro;
