import CardMenuOwner from "../../../components/CardMenuOwner"
import styled from "styled-components"
import HeaderAuthenticatedOwner from "../../../components/HeaderAuthenticatedOwner"
import IconCuadernoPersonas from "../../../assets/iconos/cuadernoPersonas.svg"
import IconMoneda from "../../../assets/iconos/moneda.svg"
import IconPropiedades from "../../../assets/iconos/propiedades.svg"
import IconHabitaciones from "../../../assets/iconos/habitaciones.svg"
import imagenInstructivo from "../../../assets/imagenes/instructivoImagen.png"
import { colorPrimario, fondo } from "../../../components/UI/variables"
import NavigationTab from "../../../components/NavigationTab"
import { useCallback, useContext, useState } from "react"
import { AuthContextOwner } from "../../../context/AuthOwner"
import BotonSubmit from "../../../components/BotonSubmit"
import { doc, getFirestore, updateDoc } from "firebase/firestore"
import appFirebase from "../../../firebase"

const StyledSection = styled.div`
  background-color: ${fondo};
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledDialog = styled.dialog`
  border-radius: 10px;
  border: 1px solid ${colorPrimario};
  align-items: center;
  width: 312px;
  height: auto;
  position: fixed;
  top: 50%;
  z-index: 1001; /* Debe estar por encima del overlay */
`;
const DivBotones = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`
const StyledImg = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  color:white;
  border: solid 5px #54ff00;
`
const PIndicacion = styled.p`
  font-weight:500;
  text-align: center;
  font-size: 16px;
`
const DivFondo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`
const StyledDiv = styled.div`
  display: flex;
  height: calc(100% - 72px - 174px);
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`
const StyledP = styled.div`
  width: 300px;
  text-align: center;
  margin: 112px 0px 24px 0px;
  font-size: 16px;
`
const HomeOwnerAuthenticated = ()=>{
    const db = getFirestore(appFirebase);

    const { currentUser } = useContext(AuthContextOwner);
    const idUsuario=currentUser[0].uid
    const popupUbicacionInstructivo=currentUser[0].popupUbicacionInstructivo
    console.log(popupUbicacionInstructivo)

    const [isOpen, setIsOpen] = useState(popupUbicacionInstructivo)
    const [aceptarOcultarMensaje, setAceptarOcultarMensaje] = useState(false);

    const manejarAceptarOcultarMensaje = (e) => {
        setAceptarOcultarMensaje(e.target.checked);
    };
    
    const handleClose = () => {
        setIsOpen(false);
    };

    const manejarEnvio = useCallback(
        async (e) => {
          e.preventDefault();
          const newRegistro = {
            popupUbicacionInstructivo: !aceptarOcultarMensaje
          };
          try {
            //en Database creamos un documento con el mismo id del Autentication y enviamos los datos a database
            const docuRef = doc(db, `usuarios/${idUsuario}`);
            await updateDoc(docuRef, { ...newRegistro });
            handleClose()
          } catch (error) {
            alert("Se ha producido un error en tu registro, revisa tus datos y vuelve a intentarlo.");
          }
        },
        [aceptarOcultarMensaje]
      );

    return(
        <StyledSection>
            {isOpen==true ? <DivFondo onClick={handleClose}> 
            <StyledImg src={imagenInstructivo}/>

            </DivFondo> :<></>}
            {isOpen==true && 
                <StyledDialog open={isOpen} onClose={handleClose}>
                    <PIndicacion>¡Bienvenido! 🐶</PIndicacion>
                    <PIndicacion>Si eres nuevo puedes darle un vistazo al instructivo en la barra inferior</PIndicacion>
                    <form onSubmit={manejarEnvio}>
                        <div>
                            <input value={aceptarOcultarMensaje} onChange={manejarAceptarOcultarMensaje}id="vinculo" type="checkbox" required title="xxxxxxxxxx"/>
                            <label htmlFor="vinculo">No volver a mostrar este mensaje</label>
                        </div>
                        <DivBotones>
                          <BotonSubmit onClick={handleClose}>Cancelar</BotonSubmit>
                          <BotonSubmit >Aceptar</BotonSubmit>
                        </DivBotones>
                    </form>
                </StyledDialog>
            }
            <HeaderAuthenticatedOwner/>
            <StyledP>Bienvenido, inicia publicando tus propiedades y habitaciones.</StyledP>
            <StyledDiv>
                <CardMenuOwner to="/AgregarPropiedades">
                    <img src={IconPropiedades} alt="Publica tu propiedad"/>
                    1. Publica tu propiedad
                </CardMenuOwner>
                <CardMenuOwner to="/AgregarHabitaciones">
                    <img src={IconHabitaciones} alt="Publica tus habitaciones"/>
                    2. Publica tus habitaciones
                </CardMenuOwner>
                <CardMenuOwner to="/RegistrarInquilinos">
                    <img src={IconCuadernoPersonas} alt="Controla tus inquilinos"/>
                    3. Controla tus inquilinos
                </CardMenuOwner>
                <CardMenuOwner to="/ControlarPagos">
                    <img src={IconMoneda} alt="Controla tus pagos"/>        
                    4. Controla tus cobros
                </CardMenuOwner>
            </StyledDiv>
            <NavigationTab/>
        </StyledSection>
    )
}

export default HomeOwnerAuthenticated