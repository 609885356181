import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'

import Titulo2 from "../../../components/Titulo2"
import AddTarjetaPropiedad from "../../../components/AddTarjetaPropiedad"
import ListaPropiedadesRegistradasOwner from "../../../components/ListaPropiedadesRegistradasOwner"
import Boton from '../../../components/Boton'

const StyledSection = styled.section`
  background-color: ${fondo}; 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 96vw;
  align-items: center;
`
const StyledDivInstruccion = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`
const StyledPNumeroInstruccion = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 13px;
  border: solid 2px black;
  background-color: black;
  margin-right: 8px;
  font-weight: bold;
  color: white;
`
const StyledPInstruccion = styled.p`
  width: 312px;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const AgregarPropiedades = () => {
  return (
    <StyledSection>
      <StyledHeader>
        <Boton to="/HomeOwnerAuthenticated">Inicio</Boton>
        <TituloStyled $naranja>Publicar Propiedades</TituloStyled>
      </StyledHeader>
      <StyledDivInstruccion>
        <StyledPNumeroInstruccion>1.</StyledPNumeroInstruccion>
        <StyledPInstruccion>Para <strong>publicar tu propiedad</strong>, inicia presionando el botón "Agregar una propiedad".</StyledPInstruccion>
      </StyledDivInstruccion>
      <Link to="/RegistroPropiedad">
        <AddTarjetaPropiedad/>
      </Link>
      <ListaPropiedadesRegistradasOwner/>
    </StyledSection>
  )
}

export default AgregarPropiedades