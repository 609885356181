import React from "react";
import appFirebase from "../../firebase";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useRef, useState, useContext, useEffect } from "react";
import { AuthContextOwner } from "../../context/AuthOwner";
import styled from "styled-components";
import Etiqueta from "../Etiqueta";
import Entrada from "../Entrada";
import AreaTexto from "../AreaTexto";
import BotonSubmit from "../BotonSubmit";
import Desplegable from "../Desplegable";
import { useParams, useNavigate } from "react-router-dom";
import { textoOscuro } from "../UI/variables";
import MapaRegistroPropiedad from "../MapaRegistroPropiedad";

const db = getFirestore(appFirebase);
const storage = getStorage(appFirebase);

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1240px;
`;
const StyledAreaTexto = styled(AreaTexto)`
  margin: 0 0 16px 0;
`;
const EntradaFile = styled(Entrada)`
  display: none;
`;
const StyledLabel = styled.label`
  width: 200px;
  height: 200px;
  border: 2px dashed ${textoOscuro};
  margin: 8px 0 8px 60px;
`;
const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`;
const StyledP = styled.p`
  width: 328px;
`

const FormEditarPropiedad = () => {
  const navigate = useNavigate();
  let { idPropiedad } = useParams();
  const { currentUser } = useContext(AuthContextOwner);
  const [departamento, setDepartamento] = useState("");
  const [distrito, setDistrito] = useState("");
  const [direccion, setDireccion] = useState("");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [imagen, setImagen] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [reglas, setReglas] = useState("");
  const [sourceImagen, setSourceImagen] = useState("");
  const [urlImagen, setUrlImagen] = useState("");
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);

  const [uuidPropiedad, setUuidPropiedad] = useState("");
  const propiedades = currentUser[1];

  useEffect(() => {
    propiedades.map((propiedad) => {
      if (propiedad.idPropiedad == idPropiedad) {
        setDistrito(propiedad.distrito);
        setDireccion(propiedad.direccion);
        setDepartamento(propiedad.departamento);
        setDescripcion(propiedad.descripcion);
        setReglas(propiedad.reglas);
        //setSourceImagen variara entre una url y un dataUrl para cambiar la imagen que se muestra en el formulario
        setSourceImagen(propiedad.imagen);
        // setUrlImagen siempre guardara la url de la imagen que se trajo desde contexto
        setUrlImagen(propiedad.imagen);
        setUuidPropiedad(propiedad.uuidPropiedad);
        setLatitud(propiedad.latitud);
        setLongitud(propiedad.longitud);
      }
    });
  }, []);

  const fileInputRef = useRef(null);

  const manejarDepartamento = (valor) => {
    setDepartamento(valor);
  };
  const manejarDistrito = (e) => {
    //detectar y almacenar valor de input en const distrito
    setDistrito(e.target.value);
  };
  const manejarDireccion = (e) => {
    //detectar y almacenar valor de input en const direccion
    setDireccion(e.target.value);
  };

  const manejarDescripcion = (e) => {
    //detectar y almacenar valor de input en const descripcion
    setDescripcion(e.target.value);
  };
  const manejarReglas = (e) => {
    //detectar y almacenar valor de input en const reglas
    setReglas(e.target.value);
  };
  const getLatitud = (latitud) => {
    setLatitud(latitud);
  };
  const getLongitud = (longitud) => {
    setLongitud(longitud);
  };
  const manejarLatitud = (e) => {
    setLatitud(e.target.value);
  };
  const manejarLongitud = (e) => {
    setLongitud(e.target.value);
  };

  const manejarImagen = (e) => {
    //detectar y almacenar archivo en const imagen
    setImagen(e.target.files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setSourceImagen(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setSourceImagen(urlImagen);
    }
  };

  const confirmarInicioDeEnvio = () => {
    setProcesoEnvioIniciado(true);
  };

  const manejarEnvio = async (e) => {
    e.preventDefault();
    confirmarInicioDeEnvio();

    let urlImagenFirebase;
    if (imagen) {
      // Si se ha seleccionado una imagen, carga la imagen al storage y obtén la URL
      const refImagen = ref(
        storage,
        `usuarios/propietarios/${currentUser[0].uid}/propiedades/${uuidPropiedad}/imagenesPropiedad/${currentUser[0].uuid}-${uuidPropiedad}-imagen1`
      ); //definimos ubicacion de imagen en storage firebase donde crearemos carpeta propiedades
      await uploadBytes(refImagen, imagen); //subimos la const imagen a la ubicacion definida en refImagen mediante la funcion uploadBytes
      //obtenemos la url de la imagen subida al storage
      urlImagenFirebase = await getDownloadURL(refImagen);
    } else {
      urlImagenFirebase = urlImagen;
    }

    const newPropiedad = {
      departamento: departamento,
      distrito: distrito,
      direccion: direccion,
      descripcion: descripcion,
      reglas: reglas,
      imagen: urlImagenFirebase,
      latitud: latitud,
      longitud: longitud,
    };

    //enviar datos recolectados al database
    try {
      if(urlImagenFirebase){
        const documentoRef = doc(db, "propiedades",idPropiedad);
        await updateDoc(documentoRef, newPropiedad);
      }
    } catch (error) {
      console.error("Error al editar propiedad:", error);
    }

    //resetamos inputs tipo texto
    setDistrito("");
    setDireccion("");
    setDescripcion("");
    setReglas("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    //nos direccionamos a la pagina donde estan registradas las propiedades
    navigate("/AgregarPropiedades");
  };

  return (
    <section>
      <StyledForm onSubmit={manejarEnvio}>
        <Etiqueta>Departamento:</Etiqueta>
        <Desplegable
          departamento={departamento}
          onSeleccionChange={manejarDepartamento}
        />
        <Etiqueta>Distrito:</Etiqueta>
        <Entrada type="text" value={distrito} onChange={manejarDistrito} required/>
        <Etiqueta>Dirección:</Etiqueta>
        <Entrada type="text" value={direccion} onChange={manejarDireccion} required/>
        <Etiqueta>Describa lo más resaltante de su propiedad:</Etiqueta>
        <StyledAreaTexto
          value={descripcion}
          onChange={manejarDescripcion}
          required
          maxLength={150}
        />
        <Etiqueta>Describa las reglas de su propiedad:</Etiqueta>
        <StyledAreaTexto
          value={reglas}
          onChange={manejarReglas}
          placeholder="Ejm: - No se permite tomar bebidas alcoholicas en las habitaciones, - etc."
        />
        <Etiqueta>Presione la imagen para cambiarla:</Etiqueta>
        <EntradaFile
          type="file"
          name="image"
          accept="image/*"
          // capture="environment"
          onChange={manejarImagen}
          ref={fileInputRef}
          id="inputImagen"
        />
        <StyledLabel htmlFor="inputImagen">
          <StyledImg src={sourceImagen} alt="Cambiar fotografia de propiedad"/>
        </StyledLabel>
        <Etiqueta>Modifica tu ubicación:</Etiqueta>
        <StyledP>Modifica la ubicación de tu propiedad tocando sobre el mapa.</StyledP>
        <MapaRegistroPropiedad
          ubicacion={departamento}
          getLatitud={getLatitud}
          getLongitud={getLongitud}
          latitud={latitud}
          longitud={longitud}
        />
        {/* <Etiqueta>Latitud:</Etiqueta>
        <Entrada type="number" value={latitud} onChange={manejarLatitud} required/>
        <Etiqueta>Longitud:</Etiqueta>
        <Entrada type="number" value={longitud} onChange={manejarLongitud} required/> */}
        <BotonSubmit $large $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>{procesoEnvioIniciado ? "Guardando... ⌛⌛" : "Guardar Cambios"}</BotonSubmit>
      </StyledForm>
    </section>
  );
};

export default FormEditarPropiedad;
